import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MarkdownPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title="Argotique" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "4rem 1rem 0 1rem",
        }}
      >
        <h1 style={{ textAlign: "center" }}>{frontmatter.title}</h1>
        <div
          className="markdownContent"
          style={{maxWidth: "45em"}}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
};

export default MarkdownPage;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
